import { CommonBG, CountDown } from '@riddler-co-jp/specc-ui-components';
import * as React from 'react';
import { useParams } from 'react-router-dom';

interface CountDownProps {}
const Energy1CountDown: React.FC<CountDownProps> = () => {
  const { trainingId } = useParams<'trainingId'>();
  return (
    <CommonBG>
      <CountDown redirect={`/${trainingId}/energy1/game`} />
    </CommonBG>
  );
};

export default Energy1CountDown;
