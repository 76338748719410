import {
  faChevronLeft,
  faChevronRight,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as React from 'react';
import { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import styled from 'styled-components';
import { useStatePersist as useState } from 'use-state-persist';

import ListBg from '../../../../static/png/listbg.png';
import Stage1Icon0 from '../../../../static/png/stage1/4-2_image01@2x.png';
import Stage1Icon1 from '../../../../static/png/stage1/4-2_image02@2x.png';
import Stage1Icon2 from '../../../../static/png/stage1/4-2_image03@2x.png';
import Stage1Icon3 from '../../../../static/png/stage1/4-2_image04@2x.png';
import Stage1Icon4 from '../../../../static/png/stage1/4-2_image05@2x.png';
import Stage1Icon5 from '../../../../static/png/stage1/4-2_image06@2x.png';
import Stage1Icon6 from '../../../../static/png/stage1/4-2_image07@2x.png';
import Stage1Icon7 from '../../../../static/png/stage1/4-2_image08@2x.png';
import Stage1Icon8 from '../../../../static/png/stage1/4-2_image09@2x.png';
import Stage1Icon9 from '../../../../static/png/stage1/4-2_image10@2x.png';
import Stage1Icon10 from '../../../../static/png/stage1/4-2_image11@2x.png';
import Stage1Icon11 from '../../../../static/png/stage1/4-2_image12@2x.png';
import Stage1Icon12 from '../../../../static/png/stage1/4-2_image13@2x.png';
import Stage1Icon13 from '../../../../static/png/stage1/4-2_image14@2x.png';
import Stage1Icon14 from '../../../../static/png/stage1/4-2_image15@2x.png';
import Stage1Icon15 from '../../../../static/png/stage1/4-2_image16@2x.png';
import Stage1Icon16 from '../../../../static/png/stage1/4-2_image17@2x.png';
import Stage1Icon17 from '../../../../static/png/stage1/4-2_image18@2x.png';
import Stage1Icon18 from '../../../../static/png/stage1/4-2_image19@2x.png';
import Stage1Icon19 from '../../../../static/png/stage1/4-2_image20@2x.png';
import Stage1Icon20 from '../../../../static/png/stage1/4-2_image21@2x.png';
import Stage1Icon21 from '../../../../static/png/stage1/4-2_image22@2x.png';
import Stage1Icon22 from '../../../../static/png/stage1/4-2_image23@2x.png';
import Stage1Icon23 from '../../../../static/png/stage1/4-2_image24@2x.png';
import Stage1Icon24 from '../../../../static/png/stage1/4-2_image25@2x.png';
import Stage1Icon25 from '../../../../static/png/stage1/4-2_image26@2x.png';
import Energy1Question from './Energy1Question';
import evidences from './Evidences.json';

interface Stage1AnswerProps {}

const Stage1Answer: React.FC<Stage1AnswerProps> = () => {
  const { trainingId } = useParams<'trainingId'>();
  const [nums, setNums] = useState<number[]>(`energy1-${trainingId}-nums`, []);
  const [focus, setFocus] = useState(`energy1-${trainingId}-focus`, 0);
  const [focusDate, setFocusDate] = useState(
    `energy1-${trainingId}-focusDate`,
    ''
  );
  const [page, setPage] = useState(`energy1-${trainingId}-page`, 0);

  useEffect(() => {
    let tmp: number[] = [];
    for (let i = 0; i < evidences.length; i++) {
      tmp = [...tmp, i];
    }
    setNums(tmp);
  }, []);

  const getIcon = (i: number) => {
    const iconList = [
      Stage1Icon0,
      Stage1Icon1,
      Stage1Icon2,
      Stage1Icon3,
      Stage1Icon4,
      Stage1Icon5,
      Stage1Icon6,
      Stage1Icon7,
      Stage1Icon8,
      Stage1Icon9,
      Stage1Icon10,
      Stage1Icon11,
      Stage1Icon12,
      Stage1Icon13,
      Stage1Icon14,
      Stage1Icon15,
      Stage1Icon16,
      Stage1Icon17,
      Stage1Icon18,
      Stage1Icon19,
      Stage1Icon20,
      Stage1Icon21,
      Stage1Icon22,
      Stage1Icon23,
      Stage1Icon24,
      Stage1Icon25,
    ];
    return iconList[i];
  };

  const changeFocus = (id: number) => {
    setFocus(id);
    const date = new Date();
    setFocusDate(String(date.getTime()));
  };

  const FocusButton = (props: { id: number }) => {
    return (
      <FocusBtn focus={props.id == focus} onClick={() => changeFocus(props.id)}>
        <Icon src={getIcon(props.id)} />
        <p>{evidences[props.id].title}</p>
      </FocusBtn>
    );
  };

  const maxItem = 10;
  const pageCount = Math.ceil(nums.length / maxItem);
  const pages = [];
  for (let i = 0; i < pageCount; i++) {
    pages.push(page == i);
  }
  const fixedNums = nums.slice(
    page * maxItem,
    Math.min(page * maxItem + maxItem, nums.length)
  );

  return (
    <MainWrapper>
      <Content>
        <List>
          <h2>証言一覧</h2>
          <ListWrapper>
            {fixedNums.map((id: number) => {
              return (
                <ListChild key={id}>
                  <FocusButton id={id} />
                </ListChild>
              );
            })}
          </ListWrapper>
          <ButtonWrapper>
            <Button
              disabled={page <= 0}
              onClick={() => {
                setPage(page - 1);
              }}
            >
              <FontAwesomeIcon icon={faChevronLeft} />
            </Button>
            <Pages>
              {pages.map((single, i) => {
                return <PageDot key={i} focus={single} />;
              })}
            </Pages>
            <Button
              disabled={page > pageCount - 2}
              onClick={() => {
                setPage(page + 1);
              }}
            >
              <FontAwesomeIcon icon={faChevronRight} />
            </Button>
          </ButtonWrapper>
        </List>
      </Content>
      <Energy1Question focus={focus} update={focusDate} />
    </MainWrapper>
  );
};

const PageDot = styled.li`
  list-style: none;
  border-radius: 99px;
  margin: 5px;
  width: 9px;
  height: 9px;

  ${(p: SingleProps) => {
    return p.focus
      ? `
      background: #F04BA4;
    `
      : `
      background: #CED4DA;
    `;
  }}
`;

const Button = styled.button`
  background: #189bf2;
  border: none;
  border-radius: 5px;
  color: white;
  padding: 12px;
  width: 40px;
  cursor: pointer;
  transition: 0.2s;

  &:hover {
    opacity: 0.8;
  }

  &:disabled {
    opacity: 0.5;
    cursor: default;
  }
`;

const ButtonWrapper = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 24px;
  padding: 0 32px;
`;

interface SingleProps {
  focus: boolean;
}

const Pages = styled.ul`
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
`;

const FocusBtn = styled.div`
  width: 180px;

  display: flex;
  padding: 4px;
  align-items: center;

  img {
    width: 60px;
    height: 60px;
    margin-right: 10px;
    margin-bottom: 0;
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.15);
  }

  p {
    font-weight: bold;
    font-size: 1.5rem;
  }

  cursor: pointer;

  ${(p: SingleProps) => {
    return p.focus
      ? `
        background: #F04BA4;
        p {
          color: white;
        }
      `
      : `
      `;
  }}
`;

const ListChild = styled.li`
  border-bottom: 2px solid #ebebeb;
  list-style: none;
  margin: 0 4px;
  padding-top: 4px;
  padding-bottom: 4px;
`;

const List = styled.div`
  width: 450px;
  height: 566px;

  background: url(${ListBg});
  background-size: contain;
  background-repeat: no-repeat;

  padding-top: 70px;
  margin-bottom: 20px;

  h2 {
    text-align: center;
    font-size: 2.8rem;
    margin-top: 10px;
    margin-bottom: 20px;
  }
`;

const ListWrapper = styled.ul`
  margin-left: 28px;
  display: flex;
  flex-wrap: wrap;
  height: 390px;
  align-content: flex-start;
`;

const Content = styled.div`
  padding: 0 20px;
  background: rgba(206, 212, 218, 0.25);
  padding-top: 30px;
  margin-bottom: -80px;
  overflow-y: scroll;
`;

const MainWrapper = styled.div`
  display: flex;
  padding-top: 10px;
  padding-bottom: 80px;
`;

const Icon = styled.img`
  width: 100%;
  display: block;
  margin-bottom: 1rem;

  &:last-child {
    margin-bottom: 8rem;
  }
`;

export default Stage1Answer;
