import { CommonBG } from '@riddler-co-jp/specc-ui-components';
import { FinishButton } from '@riddler-co-jp/specc-ui-components';
import * as React from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import styled from 'styled-components';
import { useStatePersist as useState } from 'use-state-persist';

import {
  InstructionColor,
  InstructionLogo,
  InstructionText,
} from '../../../../lib/instructionSettings';
import TimeGauge from '../../../uiElements/timeGauge/TimeGauge';
import Energy1Answer from './Energy1Answer';

interface Stage2Props {}

const Stage2: React.FC<Stage2Props> = () => {
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const { trainingId } = useParams<'trainingId'>();
  const [finished, setFinished] = useState(
    `energy1-${trainingId}-finished`,
    false
  );

  React.useEffect(() => {
    setFinished(false);
  }, [pathname]);

  return (
    <>
      <CommonBG>
        <MainWrapper>
          <TimeGauge
            timerKey={trainingId + '-energy1game'}
            duration={1800}
            stageName={InstructionText[1].title}
            color={InstructionColor}
            logo={InstructionLogo}
            onSkip={() => navigate(`/${trainingId}/energy1/result`)}
            onFinished={() => {
              setFinished(true);
            }}
            onBack={() => {
              navigate(`/${trainingId}/stages`);
            }}
          />
          <Energy1Answer />
        </MainWrapper>
      </CommonBG>
      <FinishButton
        color={InstructionColor}
        finished={finished}
        url={`/${trainingId}/energy1/result`}
      >
        以上で実習は終了です。お疲れ様でした！
      </FinishButton>
    </>
  );
};

const MainWrapper = styled.div`
  width: 100%;
  height: calc(100vh - 3rem);
  margin: 3rem auto 0;
  display: flex;
  overflow: hidden;
`;

export default Stage2;
