import { CommonBG, Rule } from '@riddler-co-jp/specc-ui-components';
import * as React from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import {
  InstructionColor,
  InstructionLogo,
  SpeccText,
} from '../../../lib/instructionSettings';
import { parentURI } from '../../..';

interface RuleInstrctionProps {
  //stageId: number;
  stageTitleImgSrc?: string;
  ruleImgSrc?: string;
  onStart?: () => void;
  onBack?: () => void;
}

const StartPage: React.FC<RuleInstrctionProps> = () => {
  const navigate = useNavigate();
  const { trainingId } = useParams<'trainingId'>();
  const stageSelectURI = `${parentURI}/trainings/${trainingId}/stages`;

  return (
    <CommonBG>
      <Rule
        mission={SpeccText.mission}
        description={SpeccText.description_start}
        video={SpeccText.video}
        onStart={() => navigate(`/${trainingId}/stages`)}
        onBack={() => (window.location.href = stageSelectURI)}
        color={InstructionColor}
        logo={InstructionLogo}
      />
    </CommonBG>
  );
};

export default StartPage;
