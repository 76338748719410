import * as React from 'react';
import styled from 'styled-components';

import PageBgSel from '../../../../static/png/bg_sel.png';
import PageBg from '../../../../static/png/bg.png';
import Stage1Icon0 from '../../../../static/png/stage1/4-2_image01@2x.png';
import Stage1Icon1 from '../../../../static/png/stage1/4-2_image02@2x.png';
import Stage1Icon2 from '../../../../static/png/stage1/4-2_image03@2x.png';
import Stage1Icon3 from '../../../../static/png/stage1/4-2_image04@2x.png';
import Stage1Icon4 from '../../../../static/png/stage1/4-2_image05@2x.png';
import Stage1Icon5 from '../../../../static/png/stage1/4-2_image06@2x.png';
import Stage1Icon6 from '../../../../static/png/stage1/4-2_image07@2x.png';
import Stage1Icon7 from '../../../../static/png/stage1/4-2_image08@2x.png';
import Stage1Icon8 from '../../../../static/png/stage1/4-2_image09@2x.png';
import Stage1Icon9 from '../../../../static/png/stage1/4-2_image10@2x.png';
import Stage1Icon10 from '../../../../static/png/stage1/4-2_image11@2x.png';
import Stage1Icon11 from '../../../../static/png/stage1/4-2_image12@2x.png';
import Stage1Icon12 from '../../../../static/png/stage1/4-2_image13@2x.png';
import Stage1Icon13 from '../../../../static/png/stage1/4-2_image14@2x.png';
import Stage1Icon14 from '../../../../static/png/stage1/4-2_image15@2x.png';
import Stage1Icon15 from '../../../../static/png/stage1/4-2_image16@2x.png';
import Stage1Icon16 from '../../../../static/png/stage1/4-2_image17@2x.png';
import Stage1Icon17 from '../../../../static/png/stage1/4-2_image18@2x.png';
import Stage1Icon18 from '../../../../static/png/stage1/4-2_image19@2x.png';
import Stage1Icon19 from '../../../../static/png/stage1/4-2_image20@2x.png';
import Stage1Icon20 from '../../../../static/png/stage1/4-2_image21@2x.png';
import Stage1Icon21 from '../../../../static/png/stage1/4-2_image22@2x.png';
import Stage1Icon22 from '../../../../static/png/stage1/4-2_image23@2x.png';
import Stage1Icon23 from '../../../../static/png/stage1/4-2_image24@2x.png';
import Stage1Icon24 from '../../../../static/png/stage1/4-2_image25@2x.png';
import Stage1Icon25 from '../../../../static/png/stage1/4-2_image26@2x.png';
import evidences from './Evidences.json';

interface Stage1QuestionProps {
  focus: number;
  update: string;
}

const Stage1Question: React.FC<Stage1QuestionProps> = props => {
  const refContent = React.useRef<HTMLDivElement>(null);
  const refItems = React.useRef<HTMLDivElement[]>([]);

  const getIcon = (i: number) => {
    const iconList = [
      Stage1Icon0,
      Stage1Icon1,
      Stage1Icon2,
      Stage1Icon3,
      Stage1Icon4,
      Stage1Icon5,
      Stage1Icon6,
      Stage1Icon7,
      Stage1Icon8,
      Stage1Icon9,
      Stage1Icon10,
      Stage1Icon11,
      Stage1Icon12,
      Stage1Icon13,
      Stage1Icon14,
      Stage1Icon15,
      Stage1Icon16,
      Stage1Icon17,
      Stage1Icon18,
      Stage1Icon19,
      Stage1Icon20,
      Stage1Icon21,
      Stage1Icon22,
      Stage1Icon23,
      Stage1Icon24,
      Stage1Icon25,
    ];
    return iconList[i];
  };

  React.useEffect(() => {
    if (refContent.current) {
      refContent.current.scrollTo({
        top: refItems.current[props.focus].offsetTop - 100,
        behavior: 'smooth',
      });
    }
  }, [props.focus, props.update]);

  return (
    <Stage1QuestionWrapper ref={refContent}>
      {evidences.map((p: any, i: number) => {
        return (
          <SingleEvidence
            focus={props.focus == i}
            key={i}
            ref={el => {
              if (el) {
                refItems.current[i] = el;
              }
            }}
          >
            <Content>
              <img src={getIcon(i)}></img>
              <div>
                <h3>{p.title}</h3>
                <p>{p.detail}</p>
              </div>
            </Content>
          </SingleEvidence>
        );
      })}
    </Stage1QuestionWrapper>
  );
};

interface SingleProps {
  focus: boolean;
}

const Content = styled.div`
  display: flex;
  padding: 18px 20px;

  img {
    width: 100px;
    height: 100px;
    margin-right: 20px;
  }

  h3 {
    font-size: 3rem;
    margin-bottom: 10px;
  }
  p {
    font-size: 1.6rem;
  }
`;

const SingleEvidence = styled.div`
  margin-bottom: 10px;

  box-sizing: border-box;
  ${(p: SingleProps) => {
    return p.focus
      ? `
    border-image: url(${PageBgSel});`
      : `
    border-image: url(${PageBg});`;
  }}

  border-image-slice: calc(50 / 184 * 100%) calc(80 / 284 * 100%) fill;
  border-image-width: 50px 190px;
`;

const Stage1QuestionWrapper = styled.div`
  height: 80rem;
  height: 100%;
  padding: 4rem;
  overflow-y: scroll;
  flex: 4;
`;

export default Stage1Question;
