import {
  CommonBG,
  MainButton,
  Result,
} from '@riddler-co-jp/specc-ui-components';
import * as React from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import styled from 'styled-components';

import {
  InstructionColor,
  InstructionLogo,
  InstructionText,
} from '../../../../lib/instructionSettings';

interface RuleInstrctionProps {
  stageTitleImgSrc?: string;
  ruleImgSrc?: string;
  onStart?: () => void;
  onBack?: () => void;
  infomations?: string[];
  conditions?: string[];
  consider_condition?: string;
  conclusions?: string[];
}

const Energy3Rule: React.FC<RuleInstrctionProps> = () => {
  const navigate = useNavigate();
  const { trainingId } = useParams<'trainingId'>();

  const text = InstructionText[1];

  const download = () => {
    // ここに回答例をダウンロードする処理を書く
    let hoge = 0;
    hoge++;
  };

  return (
    <CommonBG>
      <Result
        title={text.title}
        mission={text.mission}
        video={text.video_result}
        onBack={() => navigate(`/${trainingId}/stages`)}
        color={InstructionColor}
        logo={InstructionLogo}
      >
        <ButtonWrapper>
          <MainButton color='positive' size='large' onClick={() => download()}>
            回答例ダウンロード(未実装)
          </MainButton>
        </ButtonWrapper>
      </Result>
    </CommonBG>
  );
};
const ButtonWrapper = styled.div`
  text-align: center;
  margin: 36px 0;
`;

export default Energy3Rule;
