import { useSignIn } from '@riddler-co-jp/specc-auth-client';
import React from 'react';
import { Provider } from 'react-redux';
import { Navigate, Route, Routes, useMatch, useParams } from 'react-router-dom';

import AuthErrorPage from './components/eventProvider/common/AuthErrorPage';
import FinishPage from './components/eventProvider/common/FinishPage';
import Loading from './components/eventProvider/common/Loading';
import RedirectPage from './components/eventProvider/common/RedirectPage';
import SelectStage from './components/eventProvider/common/SelectStage';
import StartPage from './components/eventProvider/common/StartPage';
import Energy1CountDown from './components/eventProvider/stagePages/energy1/Energy1CountDown';
import Energy1Game from './components/eventProvider/stagePages/energy1/Energy1Game';
import Energy1Result from './components/eventProvider/stagePages/energy1/Energy1Result';
import Energy1Rule from './components/eventProvider/stagePages/energy1/Energy1Rule';
import Energy2Page1 from './components/eventProvider/stagePages/energy2/Energy2Page1';
import Energy2Page2 from './components/eventProvider/stagePages/energy2/Energy2Page2';
import Energy2Page3 from './components/eventProvider/stagePages/energy2/Energy2Page3';
import Store from './redux/store';
import { app } from '.';

interface ConditionedRouteRule {
  condition: boolean;
  redirectPathOnFail: string;
}
interface ConditionedRouteProps {
  rules: ConditionedRouteRule[];
}

const ConditionedRoute: React.FC<ConditionedRouteProps> = props => {
  for (const rule of props.rules) {
    if (!rule.condition) {
      return <Navigate to={rule.redirectPathOnFail} />;
    }
  }

  return <>{props.children}</>;
};

export const TrainingRouter = () => {
  const { trainingId } = useParams<'trainingId'>();
  const match = useMatch('/:trainingId/*');
  const [signedIn, signInLoading] = useSignIn(
    app,
    'energy',
    trainingId,
    match?.pathname === match?.pathnameBase ||
      match?.pathname === `${match?.pathnameBase}/`
  );
  const onlyValidUserRules = React.useMemo<ConditionedRouteRule[]>(() => {
    console.log(signInLoading, signedIn);
    return [
      {
        condition: !signInLoading && signedIn,
        redirectPathOnFail: '/auth-error',
      },
    ];
  }, [signInLoading, signedIn]);

  const handleBeforeUnloadEvent = (event: BeforeUnloadEvent): void => {
    event.returnValue =
      'このページを再読み込みしますか？入力した内容は保存されません。';
  };

  React.useEffect(() => {
    window.addEventListener('beforeunload', handleBeforeUnloadEvent);
    return () =>
      window.removeEventListener('beforeunload', handleBeforeUnloadEvent);
  }, []);

  const onExit = (url: string) => {
    window.removeEventListener('beforeunload', handleBeforeUnloadEvent);
    window.location.href = url;
  };

  if (signInLoading) {
    return <Loading />;
  }

  const page = match?.pathname.split('/') ?? [];
  if (['energy1', 'energy2'].includes(page[2])) {
    const currentPage = page.slice(3).join('/');
    if (currentPage !== '' && !currentPage.match(/introduction/)) {
      localStorage.setItem('currentpage-' + page[2], currentPage);
    }
  }

  return (
    <Provider store={Store}>
      <Routes>
        {/* energy2 */}
        <Route
          path='/energy2/page1'
          element={
            <ConditionedRoute rules={onlyValidUserRules}>
              <Energy2Page1 />
            </ConditionedRoute>
          }
        />
        <Route
          path='/energy2/page2'
          element={
            <ConditionedRoute rules={onlyValidUserRules}>
              <Energy2Page2 />
            </ConditionedRoute>
          }
        />
        <Route
          path='/energy2/page3'
          element={
            <ConditionedRoute rules={onlyValidUserRules}>
              <Energy2Page3 />
            </ConditionedRoute>
          }
        />
        {/* energy1 */}
        <Route
          path='/energy1/introduction'
          element={
            <ConditionedRoute rules={onlyValidUserRules}>
              <Energy1Rule />
            </ConditionedRoute>
          }
        />
        <Route
          path='/energy1/game'
          element={
            <ConditionedRoute rules={onlyValidUserRules}>
              <Energy1Game />
            </ConditionedRoute>
          }
        />
        <Route
          path='/energy1/result'
          element={
            <ConditionedRoute rules={onlyValidUserRules}>
              <Energy1Result />
            </ConditionedRoute>
          }
        />
        <Route
          path='/energy1/countdown'
          element={
            <ConditionedRoute rules={onlyValidUserRules}>
              <Energy1CountDown />
            </ConditionedRoute>
          }
        />
        <Route
          path='/'
          element={
            signInLoading ? (
              <Loading />
            ) : (
              <ConditionedRoute rules={onlyValidUserRules}>
                <StartPage />
              </ConditionedRoute>
            )
          }
        />
        <Route
          path='/stages'
          element={
            <ConditionedRoute rules={onlyValidUserRules}>
              <SelectStage />
            </ConditionedRoute>
          }
        />
        <Route
          path='/finish'
          element={
            <ConditionedRoute rules={onlyValidUserRules}>
              <FinishPage onExit={onExit} />
            </ConditionedRoute>
          }
        />
        <Route path='/auth-error' element={<AuthErrorPage />} />
        <Route path='/' element={<RedirectPage />} />
        <Route path='/*' element={<Navigate to='/' />} />
      </Routes>
    </Provider>
  );
};
