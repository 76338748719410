import { InstructionColors } from '@riddler-co-jp/specc-ui-components';
import * as React from 'react';

export const InstructionLogo: React.FC = () => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='249.981'
      height='60'
      viewBox='0 0 249.981 60'
    >
      <path
        d='M23.872,7.52c2,5.645,1.013,8.788-1.285,11.21-2.428,2.731-6.327,4.765-8.995,8.742C10,32.8,9.378,44.454,22.3,47.493c-5.445-3.017-6.621-11.759-.74-17.141-1.481,5.234,1.383,8.571,4.835,7.428s5.7,1.371,5.63,4.342a4.892,4.892,0,0,1-2.81,4.708,14.384,14.384,0,0,0,11.848-14.5c0-7.382-6.262-8.365-3.093-14.581-3.768.331-5.031,2.937-4.683,7.142A3.5,3.5,0,0,1,31.925,28a3.138,3.138,0,0,1-3.251.317c-1.655-1.063-1.633-3.1-.142-4.628,3.093-3.2,4.356-10.639-4.661-16.17h-.054Z'
        transform='translate(3.787 2.507)'
        fill='#fff'
        fillRule='evenodd'
      />
      <path
        d='M30,0A30,30,0,1,0,60,30,30,30,0,0,0,30,0Zm0,57.6A27.6,27.6,0,1,1,57.6,30,27.6,27.6,0,0,1,30,57.6Z'
        fill='#fff'
      />
      <path
        d='M29.7,31.1V21.922H14.751c-2.007,0-2.294-.645-2.294-1.971h15.95V13.069H12.493A1.939,1.939,0,0,1,14.751,11.1H29.7V1.85H12.851C3.317,1.85,1.74,7.011,1.74,14.431v5.556c0,6.595,2.473,11.111,11,11.111Zm30.645.036V17.3c0-4.767-3.19-7.814-8.029-7.814H40.593c-4.839,0-7.527,3.047-7.527,7.814V31.133H43.5V20.846c0-1.147.789-1.828,2.115-1.828H47.9c1.254,0,1.935.466,1.935,1.828V31.133ZM90.127,16.9V15.757c0-3.907-2.33-6.416-7.025-6.416H70.414c-5.125,0-7.2,3.369-7.2,6.667v6.81c0,5.842,2.294,8.315,7.814,8.315h18.53v-7.24H74.07c-1.147,0-1.47-.538-1.541-1.254H83.5C88.622,22.639,90.127,20.488,90.127,16.9Zm-9.642-.287c0,.538-.394.753-.932.753H72.493v-.323a1.378,1.378,0,0,1,1.541-1.4h5.52c.609,0,.932.358.932.789Zm35.054,2.4V9.484H104.177c-6.093,0-11.254,3.62-11.254,12.222v9.427H103.21V21.922a2.947,2.947,0,0,1,3.262-2.9Zm31.326-5.735V6.044l-10.43,2.043V9.52H123.891c-4.66,0-5.125,1.649-5.125,4.444v.789c0,2.688.394,3.978,2.8,4.409V21.1c-3.226.573-3.728,2.186-3.728,4.7v1c0,4.086,1.4,5.054,6.81,5.054h14.122c4.946,0,6.452-1.4,6.452-5.054v-1c0-2.688-1.362-4.91-6.452-4.91h-8.28V19.305h8.1c4.7,0,5.09-1.29,5.09-4.552v-.9Zm-13.62,1.147c0,.573-.287.824-1.183.824h-1.685c-.932,0-1.147-.287-1.147-.824v-.036c0-.466.287-.789,1.147-.789h1.685c.86,0,1.183.323,1.183.789Zm.9,11.971c0,.573-.143.824-1.147.824h-2.867c-.824,0-.9-.251-.9-.824v-.215c0-.323.108-.681.9-.681h2.867c.932,0,1.147.287,1.147.681Zm41.577-2.616V9.484h-9.892V13c0,1.075-.394,1.326-1.4,1.326h-3.763c-1.039,0-1.4-.179-1.4-1.326V9.484h-10v5.269c0,4.946,2.33,7.491,8.029,7.491h7.133a4.682,4.682,0,0,0,1.4-.179v.323c0,.717-.251,1.183-1.183,1.183H150.414v8.208h17.348C173.245,31.778,175.718,27.728,175.718,23.785Z'
        transform='translate(74.262 14.151)'
        fill='#fff'
      />
    </svg>
  );
};

export const SpeccText = {
  title: 'Energy',
  mission: '粘り強く取り組む力を身につける。',
  description_start: '説明(開始)',
  description_finish: '説明(終了)',
  video:
    'https://dl.dropboxusercontent.com/s/0zw6pts700228yk/6-1%E8%A7%A3%E8%AA%AC_1.mp4',
};

interface InstructionText {
  title: string;
  mission: string;
  description: string;
  video: string;
  video_result: string;
}

export const InstructionText: { [v: number]: InstructionText } = {
  1: {
    title: '”理由”を探せ-Whyツリー-',
    mission: '対処のため、物事の原因をできる限り探す。',
    description:
      'とある学校で起きた事件の原因を、様々な証言の中から探りましょう。Whyツリーのメソッドを使用することで、複数の原因を抜け漏れが無いように探すことが出来ます。',
    video:
      'https://dl.dropboxusercontent.com/s/0zw6pts700228yk/6-1%E8%A7%A3%E8%AA%AC_1.mp4',
    video_result:
      'https://dl.dropboxusercontent.com/s/0zw6pts700228yk/6-1%E8%A7%A3%E8%AA%AC_1.mp4',
  },
  2: {
    title: '”方法”を探せ-Howツリー-',
    mission: '手法を整理し、試行錯誤のタネを増やす。',
    description:
      '動画で出題される「お題」の利用方法を出来る限り考えましょう。その後Howツリーのメソッドを使って利用方法を整理することで、更なる別の利用方法を探しましょう。',
    video:
      'https://dl.dropboxusercontent.com/s/0zw6pts700228yk/6-1%E8%A7%A3%E8%AA%AC_1.mp4',
    video_result:
      'https://dl.dropboxusercontent.com/s/0zw6pts700228yk/6-1%E8%A7%A3%E8%AA%AC_1.mp4',
  },
};

export const InstructionColor: InstructionColors = {
  primary: '#FF6EBB',
  secondary: '#F04BA4',
  finishButton: '#E83C99',
  shadow: '#C63583',
};
