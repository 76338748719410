const Colors = {
  black: 'rgb(0, 0, 0)',

  white: 'rgb(255, 255, 255)',
  white_op090: 'rgba(255, 255, 255, 0.9)',

  // 主にページ背景の塗りに使用します。
  gray2: 'rgb(233, 236, 239)',

  // 主に罫線に使用します。これ以上の薄さは文字の塗りに設定しないでください。
  gray4: 'rgb(206, 212, 218)',
  gray4_op075: 'rgba(206, 212, 218, 0.75)',
  gray4_op025: 'rgba(206, 212, 218, 0.25)',

  // 主にアイコンの塗りに使用します。
  gray5: 'rgb(134, 144, 150)',

  // 主にキャプションなどメインの文字要素以外に使用します。
  gray6: 'rgb(134, 142, 150)',

  // 主に文字要素に使用します。
  gray8: 'rgb(52, 58, 64)',
  gray8_op090: 'rgba(52, 58, 64, 0.90)',
  gray8_op080: 'rgba(52, 58, 64, 0.80)',
  gray8_op025: 'rgba(52, 58, 64, 0.25)',

  // プライマリーカラー。主に重要なリンク要素に使用します。
  primary: 'rgb(24, 155, 242)',
  primary_op050: 'rgba(24, 155, 242, 0.50)',

  // アクセントカラー。プライマリーより目立たない範囲でアクセントに使用します。
  accent: 'rgb(25, 104, 214)',
  accent_op025: 'rgba(25, 104, 214, 0.25)',

  // ハイライトカラー。ボタンのクリック／タップ時などactiveに使用します。
  highlight: 'rgb(124, 214, 247)',

  // エラーカラー。エラーや警告を伝える際に使用します。
  error: 'rgb(234, 14, 73)',

  // ハイライトエラーカラー。エラーや警告を伝える際のハイライトに使用します。
  highlightError: 'rgb(255, 227, 227)',

  // サクセスカラー。タスクの成功や完了などを伝える際に使用します。
  success: 'rgb(5, 170, 112)',

  // ハイライトサクセスカラー。タスクの成功や完了などを伝える際のハイライトに使用します。
  highlightSuccess: 'rgb(211,249,216)',

  stPrimary: '#FF5414',
} as const;

export const colorWithAlpha = (key: keyof typeof Colors, a: number) => {
  const c = Colors[key];
  const aStr = String(a);
  return c.replace(/^rgb\(([^)]+)\)$/, (_, x) => `rgba(${x}, ${aStr})`);
};

export default Colors;
