type Stage = 'energy1';

const energy1KeyList = (trainingId: string) => [
  `@useStatePerist:energy1-${trainingId}-nums`,
  `@useStatePerist:energy1-${trainingId}-page`,
  `@useStatePerist:energy1-${trainingId}-focus`,
  `@useStatePerist:energy1-${trainingId}-finished`,
];

const keyLists = {
  energy1: energy1KeyList,
};

export const hasData = (stage: Stage, trainingId: string) => {
  const list = keyLists[stage](trainingId);
  return list.some(key => localStorage.getItem(key));
};
export const deleteData = (stage: Stage, trainingId: string) => {
  const list = keyLists[stage](trainingId);
  list.forEach(key => localStorage.removeItem(key));
};
